var carouselLikeNews = function carouselLikeNews() {
  var carousels = document.querySelectorAll('.carousel_like-news')
  if (!carousels.length) return

  carousels.forEach(function (carousel) {
    var flkty = new Flickity(carousel, {
      cellAlign: 'center',
      contain: true,
      draggable: false,
      pageDots: false,
      initialIndex: 1,
    })

    setTimeout(function () {
      disablePrevNextButton(flkty)
    }, 300)

    flkty.on('change', function () {
      disablePrevNextButton(flkty)
    })
  })
}

var disablePrevNextButton = function disablePrevNextButton(flkty) {
  var startIndex = 1
  if (!flkty.cells) return false
  var endIndex = flkty.cells.length - 2
  if (flkty.selectedIndex === startIndex) {
    flkty.prevButton.disable()
  } else if (flkty.selectedIndex === endIndex) {
    flkty.nextButton.disable()
  }
}

document.addEventListener('DOMContentLoaded', carouselLikeNews)
