class Video {
  constructor(options) {
    this.el = options.el
    this.source = this.el.data('source')
    this.overlay = $('.overlay')

    this.el.click((event) => {
      event.preventDefault()

      if (!this.source) return
      this.overlay.show()
      $('.video-modal').show().append(this.source)
    })

    this.overlay.click(function (event) {
      event.preventDefault()

      $(this).hide()
      $('.video-modal').empty().hide()
    })
  }
}

$(() => {
  $('.js-video-modal').each(function () {
    new Video({ el: $(this) })
  })
})
