$(() => {
  $('.js-collapse-menu-trigger').click(() => {
    $('.js-collapse-menu').toggle()
  })

  $('.main-nav-dropdown').click(function (e) {
    if ($(e.target).closest('.main-nav-dropdown-menu').length) {
      return
    }
    e.preventDefault()
    $(this).toggleClass('__open')
  })

  $('.js-to-top').click(() => {
    $('html, body').animate({ scrollTop: 0 }, 400)
  })
})
