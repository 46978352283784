class Tabs {
  constructor(options) {
    const obj = this
    this.elem = options.elem
    this.tabs = this.elem.find('.js-tab-item')
    this.contents = this.elem.find('.js-tab-content')

    this.tabs.click(function () {
      if ($(this).hasClass('__active')) return
      obj.showTab($(this))
    })
  }

  showTab(tab) {
    const targetContent = this.contents.filter(function () {
      $(this).data('value') == tab.data('target')
    })[0]

    this.tabs.removeClass('__active')
    this.contents.fadeTo(400, 0, function () {
      $(this).removeClass('__active')
    })

    tab.addClass('__active')
    $(targetContent).fadeTo(400, 1, function () {
      $(this).addClass('__active')
    })
  }
}

$(() => {
  $('.js-tabs').each(function () {
    new Tabs({ elem: $(this) })
  })
})
