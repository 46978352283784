class MapComponent {
  /** @param {JQuery} el */
  constructor(el) {
    this.mapEl = el
    this.points = el.data('points')
    this.settings = el.data('settings')

    ymaps.ready(this.initMap.bind(this))
  }

  initMap() {
    const map = new ymaps.Map(this.mapEl[0], {
      center: [this.settings.center_lat, this.settings.center_long],
      zoom: this.settings.zoom,
    })
    map.controls.add(new ymaps.control.ZoomControl())

    this.points.forEach((pointData) => {
      const mapPoint = new ymaps.Placemark(
        [pointData.lat, pointData.long],
        {
          balloonContentHeader: `<div class="balloon-title">${pointData.title}</div>`,
          balloonContentBody: `<div class="balloon-text">${pointData.text}</div>`,
        },
        {
          // https://yandex.ru/dev/maps/archive/doc/jsapi/2.0/ref/reference/option.presetStorage.html
          preset: 'twirl#darkorangeDotIcon',
        }
      )
      map.geoObjects.add(mapPoint)

      var glyphIcon1 = new ymaps.Placemark(
        [55.684, 37.738],
        {},
        {
          preset: 'islands#redGlyphIcon',
          iconGlyph: 'music',
          iconGlyphColor: 'red',
        }
      )

      map.geoObjects.add(glyphIcon1)
    })
  }
}

$(() => {
  const el = $('.map-component')
  if (el.length) {
    new MapComponent(el)
  }
})
