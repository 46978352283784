class SelectForm {
  constructor(options) {
    const obj = this
    this.el = options.el
    this.menu = this.el.find('.js-dropdown-menu')
    this.title = this.el.find('.js-dropdown-title')
    this.items = this.el.find('.js-dropdown-item')
    this.form = $('.subpage-form form')
    this.type = this.el.data('type')

    if (this.type == 'year') {
      this.title.text(this.form.find('select#by_year').val() || 'Год')
    } else if (this.type == 'month') {
      this.title.text(this.form.find('select#by_month').val() || 'Месяц')
    }

    this.form.find('select').change(() => this.form.submit())

    $(document).click((e) => {
      if (!$(e.target).closest(this.el).length) {
        this.close()
      }
    })

    this.el.click((e) => {
      if ($(e.target).closest('.js-dropdown-menu').length) {
        return
      }
      obj.toggle()
    })

    this.items.click(function () {
      const select_year = obj.form.find('select#by_year')
      const select_month = obj.form.find('select#by_month')
      if (obj.type == 'year') {
        select_year.val($(this).data('value'))
      } else if (obj.type == 'month') {
        select_month.val($(this).data('value'))
      }

      obj.form.submit()
    })
  }

  toggle() {
    this.el.hasClass('__open') ? this.close() : this.show()
  }

  close() {
    this.el.removeClass('__open')
  }

  show() {
    this.el.addClass('__open')
  }
}

$(() => {
  $('.js-dropdown-select').each(function () {
    new SelectForm({ el: $(this) })
  })
})
