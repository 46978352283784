import 'simplebar/dist/simplebar.css'
import SimpleBar from 'simplebar'

var scroll = function scroll() {
  Array.prototype.forEach.call(document.querySelectorAll('.custom-scroll'), function (el) {
    new SimpleBar(el)
  })
}

document.addEventListener('DOMContentLoaded', scroll)
