import SimpleBar from 'simplebar'
import { hasClassUpTree } from '~/helpers/utility-classes'

var openBook = function openBook() {
  var page = document.querySelector('.page')
  if (!page) return

  page.addEventListener('click', function (e) {
    var book = hasClassUpTree(e.target, 'open-book')

    if (book) {
      e.preventDefault()
      var modal = document.querySelector(book.getAttribute('href'))
      var vexAnimate = book.dataset.from ? 'vex_animate_' + book.dataset.from : null
      if (e.target && modal) {
        vex.closeAll()
        showVexModalWindow(modal, vexAnimate)
      }

      Array.prototype.forEach.call(document.querySelectorAll('.custom-scroll'), function (el) {
        new SimpleBar(el)
      })
    }
  })
}

var showVexModalWindow = function showVexModalWindow(modal, from) {
  return vex.open({
    className: from,
    unsafeContent: modal.innerHTML,
    showCloseButton: true,
    closeClassName: 'modal__close icon icon_26x26 icon_modal-close',
    contentClassName: modal.classList.value,
  })
}

document.addEventListener('DOMContentLoaded', openBook)
