export const hasClass = function hasClass(el, className) {
  if (el.classList) return el.classList.contains(className)
  else return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'))
}

export const addClass = function addClass(el, className) {
  if (el.classList) el.classList.add(className)
  else if (!hasClass(el, className)) el.className += ' ' + className
}

export const addClasses = function addClasses(el, classNames) {
  classNames.forEach(function (className) {
    addClass(el, className)
  })
}

export const removeClass = function removeClass(el, className) {
  if (el.classList) el.classList.remove(className)
  else if (hasClass(el, className)) {
    var reg = new RegExp('(\\s|^)' + className + '(\\s|$)')
    el.className = el.className.replace(reg, ' ')
  }
}

export const toggleClass = function toggleClass(el, className) {
  if (hasClass(el, className)) {
    removeClass(el, className)
  } else {
    addClass(el, className)
  }
}

export const getParent = function getParent(el, className) {
  while ((el = el.parentElement) && !hasClass(el, className));
  return el
}

export const hasClassUpTree = function hasClassUpTree(el, className) {
  if (hasClass(el, className)) return el
  // eslint-disable-next-line no-cond-assign
  else if ((el = getParent(el, className))) return el
}
