$(() => {
  $('.js-gallery-main').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.js-gallery-nav',
  })

  $('.js-gallery-nav').slick({
    slidesToShow: 4,
    asNavFor: '.js-gallery-main',
    dots: true,
    arrows: false,
    infinite: false,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  })
})
