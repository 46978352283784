import { removeClass, addClass } from '~/helpers/utility-classes'

var pageIndex = function pageIndex() {
  var page = document.querySelector('.page_index')
  if (!page) return

  var tabContainer = page.querySelector('.page__tabs')
  var tabs = tabContainer.querySelectorAll('.tabs__item')
  var tabsContent = page.querySelectorAll('.tabs__content')
  var activeTab = tabContainer.querySelector('.tabs__item_active')

  showTab(page, activeTab, tabsContent, tabs)

  tabs.forEach(function (tab) {
    tab.addEventListener('click', function (e) {
      e.preventDefault()

      showTab(page, tab, tabsContent, tabs)
    })
  })
}

var showTab = function showTab(page, activeTab, tabsContent, tabs) {
  var id = activeTab.getAttribute('href')
  var tabContent = page.querySelector(id)

  window.carouselBooks.forEach(function (flkt) {
    setTimeout(function () {
      flkt.resize()
    }, 500)
  })

  tabs.forEach(function (tab) {
    removeClass(tab, 'tabs__item_active')
  })

  tabsContent.forEach(function (tab) {
    addClass(tab, 'page__hidden')
  })

  addClass(activeTab, 'tabs__item_active')
  removeClass(tabContent, 'page__hidden')
}

document.addEventListener('DOMContentLoaded', pageIndex)

$(document).ready(() => {
  $('.js-head-slider').slick({
    arrows: false,
    dots: true,
  })

  $('.js-news-slider').slick({
    arrows: false,
    dots: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  })

  $('.js-basic-slider').slick({
    arrows: false,
    dots: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  })

  $('.about-expand-open').click(() => {
    $('.about-expand').addClass('__opened')
  })
})
