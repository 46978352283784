import { hasClass, addClass, removeClass } from '~/helpers/utility-classes'

const searchField = function searchField() {
  var search = document.querySelector('.search-field')
  if (search == undefined) return

  var input = search.querySelector('.search-field__input')
  var magnifier = search.querySelector('.search-field__magnifier')
  var close = search.querySelector('.search-field__close')
  var isOpen = hasClass(search, 'search-field_open')

  magnifier.addEventListener('click', function (e) {
    e.preventDefault()

    setTimeout(function () {
      input.focus()
    }, 300)
    if (!isOpen) {
      addClass(search, 'search-field_open')
      isOpen = true
    }
  })

  close.addEventListener('click', function (e) {
    e.preventDefault()
    closeSearchField()
  })

  input.onblur = function () {
    closeSearchField()
  }

  var closeSearchField = function closeSearchField() {
    input.value = ''
    removeClass(search, 'search-field_open')
    setTimeout(function () {
      isOpen = false
    }, 300)
  }
}

document.addEventListener('DOMContentLoaded', searchField)
