import { addClass, removeClass } from '~/helpers/utility-classes'

var pageAbout = function pageAbout() {
  var page = document.querySelector('.page_about')
  if (!page) return

  var tabContainer = page.querySelector('.page__tabs')
  var tabs = tabContainer.querySelectorAll('.tabs__item')
  var tabsContent = page.querySelectorAll('.tabs__content')
  var activeTab = tabContainer.querySelector('.tabs__item_active')

  showTab(page, activeTab, tabsContent, tabs)

  tabs.forEach(function (tab) {
    tab.addEventListener('click', function (e) {
      e.preventDefault()

      showTab(page, tab, tabsContent, tabs)
    })
  })
}

var showTab = function showTab(page, activeTab, tabsContent, tabs) {
  var id = activeTab.getAttribute('href')
  var tabContent = page.querySelector(id)

  tabs.forEach(function (tab) {
    removeClass(tab, 'tabs__item_active')
  })

  tabsContent.forEach(function (tab) {
    addClass(tab, 'page__hidden')
  })

  addClass(activeTab, 'tabs__item_active')
  removeClass(tabContent, 'page__hidden')
}

document.addEventListener('DOMContentLoaded', pageAbout)
