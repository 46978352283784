import 'normalize.css/normalize.css'
import '~/css/main.scss'
// Компоненты
import '~/components/search-field'
import '~/components/carousel-books'
import '~/components/carousel-like-news'
import '~/components/custom-scroll'
import '~/components/open-book'
import '~/components/gallery'
import '~/components/main-nav'
import '~/components/videos'
import '~/components/tabs'
import '~/components/select-form'
import '~/components/map-component'
// Страницы
import '~/pages/page-index'
import '~/pages/page-about'
import '~/pages/news-show'

// всегда скроллим на верх при переходе на другую страницу
document.addEventListener('DOMContentLoaded', () => new SmoothScroll().animateScroll(0))
document.addEventListener('DOMContentLoaded', () => new SmoothScroll('.to-top', { speed: 300 }))
