var carouselBooks = function carouselBooks() {
  var carousels = document.querySelectorAll('.carousel_books')
  if (!carousels.length) return

  Flickity.createMethods.push('_createPrevNextCells')
  Flickity.prototype._createPrevNextCells = function () {
    this.on('select', this.setPrevNextCells)
  }

  Flickity.prototype.setPrevNextCells = function () {
    if (this.slides.length >= 2) {
      changeSlideClasses(this.prevSlide, 'remove', 'is-prev')
      this.prevSlide = this.slides[this.selectedIndex - 1] || this.slides[this.slides.length - 1]
      changeSlideClasses(this.prevSlide, 'add', 'is-prev')
    }

    if (this.slides.length >= 3) {
      changeSlideClasses(this.nextSlide, 'remove', 'is-next')
      this.nextSlide = this.slides[this.selectedIndex + 1] || this.slides[0]
      changeSlideClasses(this.nextSlide, 'add', 'is-next')
    }
  }

  function changeSlideClasses(slide, method, className) {
    if (!slide) {
      return
    }
    slide.getCellElements().forEach(function (cellElem) {
      cellElem.classList[method](className)
    })
  }
  window.carouselBooks = []

  carousels.forEach(function (carousel) {
    window.carouselBooks.push(
      new Flickity(carousel, {
        cellAlign: 'center',
        contain: false,
        draggable: false,
        prevNextButtons: true,
        wrapAround: true,
        pageDots: false,
      })
    )
  })
}

document.addEventListener('DOMContentLoaded', carouselBooks)
